* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow: "hidden";
}

/* link tag */
a {
  color: black;
  text-decoration: none;
}

/* input tag and fields */
input {
  width: 100%;
  outline: none !important;
  outline-width: 0px !important;
  border: 0px solid !important;
  color: black;
}

input:focus,
input:active,
input:focus-visible {
  outline: none !important;
  outline-width: 0px !important;
  border: 0px solid !important;
}

.inputContainer {
  margin: 8;
  display: flex;
  align-items: center;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 4px;
}

.inputDarkMode {
  color: #ffffff;
  background-color: #424242;
}

.inputError {
  border: 1px solid red !important;
}

/* material-ui avatar */
/* .MuiAvatar-root {
  width: 32px !important;
  height: 32px !important;
  font-size: 16px;
} */

/* scrollbar */
.waScroll {
  overflow: overlay;
}

.waScroll::-webkit-scrollbar {
  height: 9px;
  width: 9px;
}

.waScroll::-webkit-scrollbar-button,
.waScroll::-webkit-scrollbar-corner {
  background: transparent;
  cursor: pointer;
}

.waScroll::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 9px;
  border: 1px solid transparent;
  background-clip: content-box;
}

.waScroll::-webkit-scrollbar-thumb:hover {
  border: 0px solid transparent;
}

.waScroll::-webkit-scrollbar-track {
  background: transparent;
}


/* workRules */
.DTtableheader {
  height: auto !important;
  padding: 16px 0px;
}